import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown";
import Footer from "../../components/Footer";
import { Link } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import ReactPlayer from 'react-player';

import {gsap, TweenLite} from 'gsap';
//import SmoothScroll from "../../components/SmoothScroll";
import { Parallax } from 'react-scroll-parallax';
import InView from 'react-intersection-observer';

import { isMobile } from "react-device-detect";


class Project extends Component{

    constructor(props){
        super(props);
        // reference to the DOM node
        this.myElement = null;
        // reference to the animation
        this.myTween = null;
        this.myTween2 = null;
        this.myScroller = null;
        this.myNext = null;

      }
    

    state = {
        project: null,
        nextProject: null,
        coord_parallax: 0,
    }

    componentDidMount(){
        
        var id = this.props.match.params.project_id;

        //console.log(id)

        axios.get(process.env.REACT_APP_BACKEND_URL + '/works/' + id)
            .then(res => {

                // seleziono il progetto che ho cliccato
                var selectedProject = res.data;

                this.setState({
                    project: selectedProject,
                })

                this.myTween = TweenLite.to(this.myElement, 1, {scale:1, opacity: 1});
                //this.myTween2 = TweenLite.to(this.myNext, 1, {scale:1.2, opacity: 1});

                // faccio in modo che il cursore si ingrandisca sui data-hover
                if (!isMobile){
                    var cursor = document.getElementById("cursor-dot-outline");
                    document.querySelectorAll("[data-hover]").forEach(el => {
                        el.addEventListener("mouseenter", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(1)";
                        });
                        el.addEventListener("mouseleave", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                        });
                    });
                }

            })

        var coord_parallax = 0;
        if (window.innerWidth < 992){
            coord_parallax = 0;
            console.log("coord parallax <992", coord_parallax)
        }else{
            coord_parallax = 80;
            console.log("coord parallax >992", coord_parallax)
        }
        this.setState({coord_parallax: coord_parallax });

    }

    componentWillUnmount(){
        this.myTween = TweenLite.to(this.myElement, 1, {scale:.8, opacity: 0});
    }

    enterNext(inView){
        if (inView){
            gsap.to("#imageNext",.85,{
                y: -60,
                delay:.25,
                ease:"power3.InOut",
                scale: 1,
                opacity:1,
                stagger: {
                    amount: .6
                }
            })
            gsap.to("#textNext",.85,{
                y: -60,
                delay:.5,
                ease:"power3.InOut",
                scale: 1,
                opacity:.4,
                stagger: {
                    amount: .6
                }
            })
        }else{
        }
    }

    enterTitle(inView){
        if (inView){
            gsap.to(['.title'],.6,{
                y: -30,
                delay:0.6,
                ease:"power3.InOut",
                opacity: 1,
                stagger: {
                    amount: .6
                }
            })
        }
    }

    moveCircle(e) {
        var w = document.getElementById("circle").clientWidth;
        TweenLite.to('.circle-project', 0.4, {
            css: {
                left: e.pageX - w/2,
                top: e.pageY + 20
              }
        });
    }

    scaleCircle(titleProj, e) {
        TweenLite.to('.circle-project',0.4,{scale:1,autoAlpha:1})
    }

    eraseCircle(e){
        TweenLite.to('.circle-project',0.4,{scale:0.1,autoAlpha:0})
    }


    render(){

        const project = this.state.project ? (
          
            <div className="project" >
                <Helmet>
                    <title>{ this.state.project.title }</title>
                    <meta name="description" content={this.state.project.content ? this.state.project.content.slice(0,100) : this.state.project.title} />
                </Helmet>
                {/* <Row className="mt-5 pb-5 mb-5">
                    <Col md={10} xl={8} className="pt-5 mx-auto">
                        <div className="project-wrap" >
                            <InView onChange={inView => this.enterTitle(inView)}>
                                <h1 className="title project-title text-center">{ this.state.project.title }</h1>
                            </InView>
                            <img ref={div => this.myElement = div}
                                className="d-block img-fluid project-mainimg"
                                src={process.env.REACT_APP_BACKEND_URL + this.state.project.image.url}
                                alt=""
                                width="1280"
                                height="800"
                                />
                        </div>
                    </Col>
                </Row> */}
                <Row className="align-items-start pt-5">
                    <Col lg={6}>
                        <div className="pt-5">
                            <h1 className="subtitle pt-5">{ this.state.project.title }</h1>
                            {this.state.project.content ?
                                <ReactMarkdown source={this.state.project.content} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}}/> 
                                :
                                null
                            }
                            <div className="project-info">
                                <p className="mb-0"><strong>Cliente:</strong> {this.state.project.client.name}</p>
                                <p className="mb-0"><strong>Anno:</strong> {this.state.project.date}</p>
                                {/* <p className="mb-0"><strong>Categoria:</strong> {this.state.project.category.name}</p> */}
                            </div>
                        </div>
                    </Col>
                
                    {this.state.project.gallery.map((image, index)=>{
                        var classes="";

                        if (index % 2 == 0){
                            if (index == 0){
                                classes="parallax-1"
                            }

                            return(
                                <Col id={index} key={index} lg={6}> 
                                 {/* <InView onChange={inView => this.enterImage(index, index*.25, inView)}> */}
                                    <Parallax className={classes} y={[`-${this.state.coord_parallax}px`, `${this.state.coord_parallax}px`]} tagOuter="figure">
                                        { image.name.toString().endsWith("mp4")  ? 
                                            <div className="player-wrapper ">
                                                <ReactPlayer 
                                                    url={process.env.REACT_APP_BACKEND_URL + image.url} 
                                                    controls 
                                                    playsinline={true} 
                                                    className="react-player" 
                                                    width='100%'
                                                    height='100%' />
                                            </div>
                                            : 
                                            <img id={"image"+index}
                                                className="project-img d-block img-fluid mb-5"
                                                src={process.env.REACT_APP_BACKEND_URL + image.url}
                                                alt={this.state.project.title}
                                                //width="1280"
                                                //height="800"
                                            />
                                        }
                                    </Parallax>
                                    {/* </InView> */}
                                </Col>
                               
                            )
                        }else{
                            return (
                                <Col key={index} lg={6} className="">
                                    {/* <InView onChange={inView => this.enterImage(index, index*.25, inView)}> */}
                                    <Parallax className="" y={[`${this.state.coord_parallax}px`, `-${this.state.coord_parallax}px`]} tagOuter="figure">
                                    { image.name.toString().endsWith("mp4")  ? 
                                            <div className="player-wrapper">
                                                <ReactPlayer 
                                                    url={process.env.REACT_APP_BACKEND_URL + image.url} 
                                                    controls 
                                                    playsinline={true} 
                                                    className="react-player" 
                                                    width='100%'
                                                    height='100%' />
                                            </div>
                                            : 
                                            <img id={"image"+index}
                                                className="project-img d-block img-fluid mb-5"
                                                src={process.env.REACT_APP_BACKEND_URL + image.url}
                                                alt={this.state.project.title}
                                                //width="1280"
                                                //height="800"
                                            />
                                        }
                                    </Parallax>
                                    {/* </InView> */}
                                </Col>
                            )
                        }
                    }
                    )}
                </Row>

            </div>
        ) : (
            // <div className="text-center page project-img">
            //     Loading project...
            // </div>
            null
        )

        const nextProject = this.state.nextProject ? (
           
                <InView threshold={.5} onChange={inView => this.enterNext(inView)}>
                    <Link data-hover className="d-block" to={'/project/' + this.state.nextProject.id } onMouseEnter={(e) => this.scaleCircle(project.title,e)} onMouseMove={(e) => this.moveCircle(e)} onMouseLeave={(e) => this.eraseCircle(e)}>
                        {/* <h3 id="titleNext" className="mb-4 title next-title">{ this.state.nextProject.title }</h3> */}
                        <div id="imageNext" className="next-img" style={{ 'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL + this.state.nextProject.image.url+ ''}} />
                    </Link>
                </InView>
            
        ) : (
            // <div>
            //     Loading next project...
            // </div>
            null
        )

        const nextTitleProject = this.state.nextProject ? (
            // uso una classe diversa rispoetto a quella della home perché a volterimaneva incantata la scritta nel cambio pagina
            <div id="circle" className="circle-project">{this.state.nextProject.title}</div>
        ) : (
            null
        )

        return( 
            <div className="page">
               {nextTitleProject}
                <Container fluid={true}> 
                <BackButton />
                    { project }
                    {/* <Row className=" project-next">
                        <Col md={8} xl={6} className="mt-5 mx-auto">
                            <div className="project-wrap" ref={div => this.myNext = div}>
                                <span className="next-text" id="textNext">Next</span>
                                { nextProject }
                            </div>
                        </Col>
                    </Row> */}
                </Container> 
                <div className="footer-wrap">
                    <Footer />
                </div>
            </div>
        )
        
    }
}

export default Project