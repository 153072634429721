import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/Footer";
import gsap from 'gsap';


class About extends React.Component {

    componentDidMount(){

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })
    }
    render(){

        return(
            <div className="page page-height about d-flex flex-column">
                <Helmet>
                    <title>Chi sono - Leandro Agostini</title>
                    <meta name="description" content="Nato a Torino il 7 aprile 1960. Espressione della lingua dei segni dal Liceo Artistico all’Accademia Albertina, dalla Facoltà di Architettura all’Atelier ABC." />
                </Helmet>
                <Container fluid={true} className="position-relative">
                    <Row className="align-items-stretch">
                        <Col lg={6} className="">
                            <div className="img-fixed about-img">
                                <h1 className="title title-fixed">Chi sono</h1>
                            </div>
                        </Col>
                        <Col lg={6} className="mb-lg-5 pt-lg-5">
                            <div className="px-lg-4 pt-lg-5 pb-5 pb-lg-0 pt-5">
                                {/* <h2 className="subtitle pt-5 mb-5">Nulla dies sine linea</h2> */}
                                <p>Questa presentazione parla del <i>fare</i> che diventa <i>essere</i>, parla di cosa faccio per dire chi sono. Dunque, condivide lavori e progetti che sono parte di me, concretizzati o semplicemente immaginati, e coprono più di trent’anni di attività. Sono accompagnati da brevi commenti, citazioni, racconti che, in qualche modo, ne illustrano la genesi. </p>
                                <p>Sono tutti espressione della lingua dei segni. Si possono riconoscere segni dal Liceo Artistico, dalla Scuola del nudo e anatomia dell’Accademia Albertina, dalla Facoltà di Architettura, dal c.a.v. Dravelli, dall’Atelier ABC (il mio primo studio). Difficile riassumere in poche parole tutti gli ambiti del mio lavoro. Tutte le discipline che cerco di frequentare si sovrappongono confluendo poi in un’unica risposta riconoscibile, la mia.</p>
                                <p>Compaiono fasi di preparazione e allestimento, ritratti di persone che mi sono state vicine. Ho avuto la fortuna di incontrare lungo il mio percorso quelli che considero i miei maestri. Non lo sanno, e a loro va il mio grazie. Così come il mio grazie va alla mia famiglia, che da sempre mi sostiene, anche quando sono pesante. E lo sono, fidatevi. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="footer-wrap mt-auto">
                    <Footer is_fixed={true} />
                </div>
            </div>
        )
    }
}

export default About