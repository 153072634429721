import React, { Component,  } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import CardProject from '../../components/CardProject';
import Footer from "../../components/Footer";
import axios from 'axios';
import { Cookies } from "react-cookie-consent";
import ReactMarkdown from "react-markdown";
import Swiper from 'swiper';
import {TweenMax, TweenLite}  from 'gsap';

import { isMobile } from "react-device-detect";

var swiperHome;

class Home extends Component {

    constructor(props) {
        
        super(props);

        this.state = {
            projects: [ ],
            featuredItemId: -1,
            cover: null,
            titleProject: "",
            textProject: "",
            scrollTop: 0
        }

        this.acceptCookie = this.acceptCookie.bind(this);
        
    }

    componentDidMount(){

        window.addEventListener('scroll', this.handleScroll, true);
        this.handleScroll();

        axios.get(process.env.REACT_APP_BACKEND_URL + '/cover')
            .then(res => {
                //console.log(res)
                this.setState({
                    cover: res.data,
                })

            })


        axios.get(process.env.REACT_APP_BACKEND_URL + '/works?in_home=true&_sort=id:DESC')
            .then(res => {
                //console.log(res)

                var random = Math.floor(Math.random() * res.data.length)

                this.setState({
                    projects: res.data,
                    featuredItemId: random,
                    textProject:res.data[0].content_home
                })

                //TweenLite.to('.finale',0.4,{ y:150,autoAlpha:1})

                    swiperHome = new Swiper( document.querySelectorAll('#swiperHome'), {
                        //pagination: ".swiper-pagination",
                        //direction: 'horizontal',
                        resistanceRatio:0.85,
                        slidesPerView: 'auto',
                        //longSwipes:false,
                        //longSwipesRatio:0.5,
                        //touchRatio:5,
                        //paginationClickable: true,
                        //slideToClickedSlide: true,
                        spaceBetween: 0,
                        //mousewheel: true,
                        //parallax: true,
                        speed: 600,
                        // autoplay: {
                        //     delay: 4500
                        // },
                        // loop: true,
                        // loopAdditionalSlides: 0,
                        // loopedSlides:200,
                        //grabCursor: true,
                        //freeMode:true,
                        navigation: {
                            nextEl: document.getElementById( 'swiper-button-next' ),
                            prevEl: document.getElementById( 'swiper-button-prev' )
                        },
                        // controller:{
                        //     control: swiperHome2
                        // },
                        on: {
                            reachEnd: function() {
                                this.snapGrid = [...this.slidesGrid];
                              },
                            slideChange:function(){

                                TweenLite.to('#testo',0.5,{autoAlpha:1})

                                if (swiperHome != undefined){
                                    if (swiperHome.activeIndex < swiperHome.slides.length){
                                        this.setState({
                                            textProject:res.data[swiperHome.activeIndex].content_home
                                        });

                                    if(swiperHome.activeIndex == swiperHome.slides.length - 2){
                                        swiperHome.snapGrid[swiperHome.snapGrid.length - 1] = swiperHome.slidesGrid[swiperHome.slidesGrid.length - 1];
                                    }
                                    //     if (document.getElementById( 'swiper-button-next' ).classList.contains('swiper-button-disabled')){
                                    //                 document.getElementById( 'swiper-button-next' ).classList.remove('swiper-button-disabled');
                                    //    }
                                    }

                                    // if (swiperHome.activeIndex == swiperHome.slides.length - 2){
                                    //     document.getElementById( 'swiper-button-next' ).classList.add('swiper-button-disabled');
                                    // }

                                    // if (swiperHome.activeIndex == swiperHome.slides.length - 2){
                                    //     document.getElementById( 'swiper-button-next' ).classList.add('swiper-button-disabled');
                                    // }
                                    // if (swiperHome.activeIndex < swiperHome.slides.length - 2){
                                    //    if (document.getElementById( 'swiper-button-next' )){
                                    //        document.getElementById( 'swiper-button-next' ).classList.remove('swiper-button-disabled');
                                    //    }

                                    // }
                                  
                                    
                                }

                                

                                
                            }.bind(this),
                            touchMove:function(){
                                //TweenMax.to('.swiper-link', 0.2,{scale: 0.9});
                                // this.setState({
                                //     textProject:res.data[swiperHome.realIndex].content
                                // });
                            }.bind(this),
                            touchEnd:function(){
                                //TweenMax.to('.swiper-link', 0.4,{scale:1, delay:0.3});
                                // this.setState({
                                //     textProject:res.data[swiperHome.realIndex].content
                                // });
                                
                            }.bind(this),
                            progress: function(progress){
                                if(progress == 1){
                                    console.log('sono alla fine')
                                    //TweenLite.to('.finale',0.4,{ y:0,autoAlpha:1, delay: .5})
                                    
                                    // this.setState({
                                    //     textProject:res.data[swiperHome.slides.length - 1].content_home
                                    // });
                                   
                                }else{
                                    //TweenLite.to('.finale',0.4,{ y:600,autoAlpha:1})
                                    
                                }
                                if(progress > 0){
                                    //TweenLite.to('.intro-text',0.5,{autoAlpha:0})
                                    console.log('cook: ',Cookies.get('gdprCookie'))
                                }else{
                                   // TweenLite.to('.intro-text',0.5,{autoAlpha:1})
                                }
                            }.bind(this),
                        }

                    });

                    swiperHome.init();
                    swiperHome.snapGrid[swiperHome.snapGrid.length - 1] = swiperHome.slidesGrid[swiperHome.slidesGrid.length - 1];

            })

            TweenLite.from('#nav',1,{ autoAlpha:0, delay: 0})
            TweenLite.to('#nav',1,{ autoAlpha:1, delay: 2})

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);

        swiperHome.destroy();
      }

  


    acceptCookie(){
        Cookies.set('gdprCookie', true)
        this.setState({
            gdprCookie: 'true'
        });
    }

    openProject(id, e) {
        // e.preventDefault();
        // console.log(id);
        //TweenMax.to('#box-'+id, 0.2,{scale: 3});
    }

    moveCircle(e) {
        var w = document.getElementById("circle").clientWidth;
        TweenLite.to('.circle', 0.4, {
            css: {
                left: e.pageX - w/2,
                top: e.pageY + 20
              }
        });
        //console.log('move')
      }

      scaleCircle(titleProj, e) {
        this.setState({
            titleProject: titleProj
        });
        TweenLite.to('.circle',0.4,{scale:1,autoAlpha:1})
        //console.log('scale')

        // ingrandisco il cursore del mouse all'hover
        if (!isMobile){
            var cursor = document.getElementById("cursor-dot-outline");
            cursor.style.transform = "translate3d(-50%, -50%,0) scale(1)";
        }
    }

    eraseCircle(e){
        TweenLite.to('.circle',0.4,{scale:0.1,autoAlpha:0})

        // rimpicciolisco il cursore del mouse quando esco dall'hover
        if (!isMobile){
            var cursor = document.getElementById("cursor-dot-outline");
            cursor.style.transform = "translate3d(-50%, -50%, 0) scale(0.2)";
        }
    }

    handleScroll() {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        //console.log(`onScroll, window.scrollY: ${scrollY} `)
        if (scrollY < 120){



            // document.getElementById('nav').classList.add("nav-show");
            // console.log(`onScroll, window.scrollY: ${scrollY} `,  document.getElementById('nav'))
        }else{
            //document.getElementById('nav').classList.remove("nav-show");
            //TweenLite.to('#nav',1,{ autoAlpha:1, delay: .5})
        }
      }

      



    render() {

        const { projects, featuredItemId, cover } = this.state;
    
        var featuredItem = projects[featuredItemId];

        const randomProject = featuredItem ? (
            featuredItem.image.url
        ): (
            <div className="text-center">Loading...</div>
        )

        const coverImg = cover ? (
            cover.image.url
        ): (
            <div className="text-center">Loading...</div>
        )

        const styleImgBg = {
            'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL + coverImg +')',
            'height': window.innerHeight,
            'maxHeigt': window.innerHeight,
        };

        const styleHero = {
            'marginTop' :  window.innerHeight,
        }

        const projectList = projects.length ? (

            projects.map( (project, index) => {
               
                return (
                    
                    <div className="swiper-slide" id={'box-' + project.id } key={project.id}>
                        <Link onClick={(e) => this.openProject(10, e) }  onMouseEnter={(e) => this.scaleCircle(project.title,e)} onMouseMove={(e) => this.moveCircle(e)} onMouseLeave={(e) => this.eraseCircle(e)} className="swiper-link" to={'/project/' + project.id }>
                            <CardProject project={project} title={project.title} enterdelay={index*.35} />
                        </Link> 
                    </div>
                )
            })

        ) : (
            <div className="text-center">Loading...</div>
        )

        const projectListText = projects.length ? (

            projects.map( (project, index) => {
               
                return (
                    <div className="swiper-slide2 d-block" key={project.id}>
                        {project.title}
                    </div>
                )
            })

        ) : (
            <div className="text-center">Loading...</div>
        )


        return(
            <div className="page">
                
                    <div id="circle" className="circle"></div>
                    <Helmet>
                        <title>Leandro Agostini - Nulla dies sine linea</title>
                        <meta name="description" content="Grafica e comunicazione dal 1988. Ho lavorato per committenti come Fondazione per la Cultura Torino e Fondazione Torino Musei, oltre a molti soggetti privati." />
                    </Helmet>
                    <div className="home" style={styleImgBg} ></div>
                    <div className="d-flex flex-column" >

                        <div className="motto">grafica e comunicazione</div>

                        <div className="scroll-down">
                            <div className="scroll-down-text">Scroll</div>
                            <div className="scroll-down-bar"></div>
                        </div>
                    
                        <div className="hero d-flex align-items-start align-items-lg-center pt-5 pt-lg-auto"  style={styleHero}>
                            <div className="d-flex align-items-center flex-column flex-lg-row">
                                <div className="swiper-text position-relative d-flex flex-column align-self-stretch justify-content-start mb-5 pl-3 pr-lg-5 order-lg-1 order-2">
                                    <h1 className="sr-only">Leandro Agostini</h1>
                                    {/* <h2 className="subtitle">Nulla dies sine linea</h2> */}
                                    <div className="d-flex">
                                        <div>
                                            <ReactMarkdown id="testo" source={this.state.textProject} className=""/>
                                            {/* <p id="testo">Nato a Torino, il 7 aprile 1960. Studi artistici e di architettura con inizio dell’attività professionale nel 1988. Ex membro del gruppo di ricerca artistica ACE. Collezionista di matite. Progetta sistemi di comunicazione e identità visive.</p>  */}
                                            <div className="reddy" />
                                        </div>
                                    </div>
                                    {/* <div id="swiperHome2" className="swiper-container">
                                        <div className="swiper-wrapper">
                                            {projectListText} 
                                        </div>
                                    </div> */}
                                    <div className="swiper-navigation swiper-navigation-home">
                                        <div data-hover className="swiper-button-prev" id="swiper-button-prev"></div>
                                        <div data-hover className="swiper-button-next" id="swiper-button-next"></div>
                                    </div>
                                </div> 
                            
                                <div className="swiper-home d-flex align-items-center pb-5 order-lg-2 order-1 mt-5 mt-lg-0" >
                                    <div id="swiperHome" className="swiper-container">
                                        <div className="swiper-wrapper">
                                            {projectList} 
                                            {/* <div className="swiper-slide"></div>  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-home">
                                <div className="footer-wrap mt-auto ">
                                    <Footer/>
                                </div>
                            </div>
                        </div>
                    
                    </div>

            </div>
        )
    }
}

export default Home