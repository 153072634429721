import React from 'react';
import { ReactSVG } from 'react-svg';
import Toggle from '../../components/Toggle';
import useDarkMode from 'use-dark-mode';

import SunImg from '../../assets/images/sun.svg';
import MoonImg from '../../assets/images/moon.svg';


function DarkMode (){

    const darkMode = useDarkMode(true);
   
    return ( 

            <div className="position-absolute dark-mode-toggle">
                <button type="button" onClick={darkMode.disable} data-hover>
                    <ReactSVG src={SunImg} />
                </button>
                <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
                <button type="button" onClick={darkMode.enable} data-hover>
                    <ReactSVG src={MoonImg} />
                </button>
            </div>

    )

}


export default DarkMode