import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown";
import Footer from "../../components/Footer";

import { Link } from "react-router-dom";
import CardProject from '../../components/CardProject';

import { isMobile } from "react-device-detect";


import Swiper from 'swiper';

class Client extends Component{

    state = {
        client: null,
    }

    componentDidMount(){
        
        var id = this.props.match.params.client_id;

        console.log(id)

        axios.get(process.env.REACT_APP_BACKEND_URL + '/clients/' + id)
            .then(res => {
                this.setState({
                    client: res.data
                })

                document.querySelectorAll('.swiper-container').forEach(function(elem) {
                    new Swiper(elem, {
                        pagination: ".swiper-pagination",
                        paginationClickable: true,
                        //mousewheel: true,
                        spaceBetween: 0,
                        keyboardControl: true,
                        speed: 600,
                        slidesPerView: 'auto',
                        navigation: {
                            // nextEl: elem.nextElementSibling.nextElementSibling,
                            // prevEl: elem.nextElementSibling,
                            nextEl: elem.parentNode.getElementsByClassName( 'swiper-button-next' ),
                            prevEl: elem.parentNode.getElementsByClassName( 'swiper-button-prev' )
                        }
                    });
                });

                // faccio in modo che il cursore si ingrandisca sui data-hover
                if (!isMobile){
                    var cursor = document.getElementById("cursor-dot-outline");
                    document.querySelectorAll("[data-hover]").forEach(el => {
                        el.addEventListener("mouseenter", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(1)";
                        });
                        el.addEventListener("mouseleave", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                        });
                    });
                }

            })

    }

    render(){

        /* const styleImgBg = this.state.client ? (
            { 
                'backgroundImage': 'url('+process.env.REACT_APP_BACKEND_URL +  this.state.client.cover.url +')',
            } 
        ):(
            null
        ); */
 
        const client = this.state.client ? (

            <div className="client" >
                <Helmet>
                    <title>{ this.state.client.name }</title>
                    <meta name="description" content={this.state.client.description ? this.state.client.description.slice(0,100) : this.state.client.name} />
                </Helmet>
                <h1 className="client-title d-inline-block">{ this.state.client.name }</h1>
                <div data-hover className="swiper-navigation position-relative d-inline-block">
                    <div data-hover className="swiper-button-prev"></div>
                    <div data-hover className="swiper-button-next"></div>
                </div>
                <div className="client-swiper swiper-container">
                    <div className="swiper-wrapper">
                        {this.state.client.works.map( (project, index) => {
                            return (
                                <div className="swiper-slide" id={'box-' + project.id } key={project.id}>
                                    <Link data-hover className="swiper-link swiper-link-archive position-relative" to={'/project/' + project.id }>
                                        <CardProject project={project} title={project.title} enterdelay={index*.35} />
                                        {/* <span className="swiper-cardname">{project.title}</span> */}
                                    </Link>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                {/*
                <div className="mt-5 py-5 d-flex align-items-start">
                    <div className="client-logo ml-3 mr-5">
                        <img src={process.env.REACT_APP_BACKEND_URL +  this.state.client.logo.url} className="img-fluid"  alt={ this.state.client.name } />
                    </div>
                    <div className="client-description pr-5">
                        <ReactMarkdown source={this.state.client.description} className=""/>
                    </div>
                </div>
                */}
            </div>
           
        ) : (
            <div className="text-center page">
                Loading cliente...
            </div>
        )

        return(
            <div className="page page-client">
                <Container fluid="true">
                    <Row className="pb-5 mb-5">
                        <Col sm={12} className="mt-5 mx-auto">
                            { client }
                        </Col>
                    </Row>
                    <div className="footer-wrap mt-auto">
                        <Footer is_fixed={true} />
                    </div>
                </Container>
            </div>
        )
        
    }
}

export default Client