import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "../../components/Header";
import Nav from "../../components/Nav";
import Home from "../Home";
import Clients from "../Clients";
import Client from "../Client";
import About from "../About";
import Archive from "../Archive";
import Project from "../Project";
import Signs from "../Signs";
import ThankYou from "../ThankYou";
import Contact from "../Contact";
import Privacy from "../Privacy";
import NoMatch from "../NoMatch";

import ScrollToTop from '../../components/ScrollToTop';
import Cursor from '../../components/Cursor';

import {CSSTransition, TransitionGroup} from 'react-transition-group';
import gsap from 'gsap';
import CookieConsent, { Cookies } from "react-cookie-consent";
import withTracker from '../../components/withTracker';
import ReactGA from 'react-ga4';

import { isMobile } from "react-device-detect";




function CookieBar() {
    const acceptedCookies = Cookies.get("gdprCookie");
  
    function callCookies() {
        console.log('Cookie ok');
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  
    if (acceptedCookies === "true") {
        callCookies();
        return null;
    }
  
    return (
        <CookieConsent
            onAccept={() => callCookies()}
            acceptOnScroll={true}
            acceptOnScrollPercentage={0}
            //debug={true}
            onDecline={() => {console.log("Cookie bloccato!")}}
            buttonText="Accetto i cookie"
            cookieName="gdprCookie"
            style={{ background: "#262626", padding: "25px 15px" }}
            buttonStyle={{ color: "#262626", fontWeight: "700", background: "#e42619", textTransform: "uppercase" }}
            expires={150}
            >
            Questo sito utilizza i cookie.{" "}
            <a href="/privacy" style={{ color: "white", textDecoration: 'underline' }}>
            Clicca qui 
            </a> per maggiori informazioni su come utilizziamo i cookie e come modificare le tue impostazioni. Se continui la navigazione acconsenti all'utilizzo dei cookie da parte nostra. 
        </CookieConsent>
    );
}




function App() {  

    useEffect(() => {    
        // faccio in modo che all'inizio il cursore sia piccolo
        if (!isMobile){
            var cursor = document.getElementById("cursor-dot-outline");
            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
            document.getElementById("cursor-dot").style.transform ="translate(-50%, -50%) scale(0.2)"; 
        }
    });

    // function callCookies(){
    //     console.log('Cookie ok');
    //     ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }

    const onEnter = node => {
        // enter animation
        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })
    }

    const onExit = node => {
        // exit animation
        //console.log(node)
    }

    return ( 
        <div className="all asscroll-container">
            <Router>
            <ScrollToTop />
            <Header />
            <Nav />
            <Route render={({location}) => (
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        timeout={{ enter: 1200, exit: 1200 }}
                        classNames="fade"
                        onExit={onExit}
                        onEnter= {onEnter}
                        unmountOnExit
                        >
                        <Switch location={location}>
                            <Route path="/" component={withTracker(Home)}   exact />
                            {/* <Route path="/article/:id" component={withTracker(Article)} exact />
                            <Route path="/category/:id" component={withTracker(Category)} exact /> */}
                            <Route path="/archive" component={withTracker(Archive)} exact />
                            <Route path="/project/:project_id" component={withTracker(Project)} exact />
                            <Route path="/contact" component={withTracker(Contact)} exact />
                            <Route path="/clients" component={withTracker(Clients)} exact />
                            <Route path="/client/:client_id" component={withTracker(Client)} exact />
                            <Route path="/signs" component={withTracker(Signs)} exact />
                            <Route path="/chi-sono" component={withTracker(About)} exact />
                            <Route path="/privacy" component={withTracker(Privacy)} exact />
                            <Route path="/thank-you" component={withTracker(ThankYou)} exact />
                            <Route component={withTracker(NoMatch)} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
               
            )} />
            </Router>
           {isMobile ?
            null:
            <Cursor/>
           }
            <CookieBar />
        </div>
    );
}

export default App;  