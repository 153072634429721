
import React, { Component } from 'react';
import {gsap} from 'gsap';

class Footer extends Component{

    constructor(props){
        super(props);
      }
    
    render() {

    return ( 
        <footer className={"p-3 mt-lg-5 " + (this.props.is_fixed ? 'footer-fixed' : 'footer-free')}>
            {/* <p className="mb-0">Leandro Agostini | GSTLDR60D07L219D - 09685420011<br></br>
            <a href="tel:0114364665">0114364665</a> | <a href="mailto:info@agostinileandro.it">info@agostinileandro.it</a> | Via Vittorio Andreis 18/10F - 10152 Torino (Italia)  </p> */}
            <p className="mb-0"><span className="footer-motto d-block d-sm-inline">grafica e comunicazione</span> © <span className="d-lg-none d-xl-inline">Copyright</span> 2023 | <a href="/privacy">Privacy Policy</a> | <a href="https://doppiozero.to/" target="_blank" rel="noopener noreferrer">Made by doppiozero</a></p>
        </footer>
    )
        }

}


export default Footer;