import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import DarkMode from '../../components/DarkMode';


class Nav extends React.Component {

    componentDidMount(){

        //document.getElementById('nav').classList.add("nav-show");
    
    }

    render(){
        return (
            (this.props.is_mobile ?
                <Menu right bodyClassName={ "menu-mobile-open" }>
                    <DarkMode/>
                    <a id="chi-sono" className="menu-item" href="/chi-sono">chi sono</a>
                    <a id="archive" className="menu-item" href="/archive">archivio</a>
                    <a id="clients" className="menu-item" href="/clients">clienti</a>
                    <a id="signs" className="menu-item" href="/signs">segni</a>
                    <a id="contact" className="menu-item" href="/contact">contatti</a>
                </Menu>
            :
                <div id="nav" className="nav justify-content-end d-none d-lg-flex">
                    <Link data-hover id="chi-sono" className="menu-item" to="/chi-sono">chi sono</Link>
                    <Link data-hover id="archive" className="menu-item" to="/archive">archivio</Link>
                    <Link data-hover id="clients" className="menu-item" to="/clients">clienti</Link>
                    <Link data-hover id="signs" className="menu-item" to="/signs">segni</Link>
                    <Link data-hover id="contact" className="menu-item" to="/contact">contatti</Link>
                </div>
            )

        );
    }

};

export default Nav;