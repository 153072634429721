import React from "react";
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/Footer";

import axios from 'axios';
import gsap from 'gsap';

import { isMobile } from "react-device-detect";


class Clients extends React.Component {

    state = {
        clients: [ ],
    }

    componentDidMount(){

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })

        axios.get(process.env.REACT_APP_BACKEND_URL + '/clients')
            .then(res => {
                //console.log(res)
                this.setState({
                    clients: res.data,
                })

                // faccio in modo che il cursore si ingrandisca sui data-hover
                if (!isMobile){
                    var cursor = document.getElementById("cursor-dot-outline");
                    document.querySelectorAll("[data-hover]").forEach(el => {
                        el.addEventListener("mouseenter", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(1)";
                        });
                        el.addEventListener("mouseleave", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                        });
                    });
                }


            })

    }

    render(){

        const { clients } = this.state;

        clients.sort((a, b) => a.name.localeCompare(b.name));

        const clientList = clients.length ? (

            clients.map( client => {

                return (
                    <Col sm={12} key={client.id} className="client-wrap mx-auto mb-4">
                        {/* <Link data-hover to={'/client/' + client.id } className="client-link" >
                        <img src={process.env.REACT_APP_BACKEND_URL + client.logo.url} alt={client.name} className="img-fluid mr-3"/>
                        </Link> */}
                        {client.works.length > 0 ? 
                            <Link data-hover to={'/client/' + client.id } className="client-link subtitle d-inline-block" >
                                {client.name}
                            </Link>
                        :
                            <div className="client-link subtitle d-inline-block" >
                                {client.name}
                            </div>
                        }
                    </Col>
                )
            })
        ) : (
            <div className="text-center">Loading...</div>
        )

        return(
            <div className="page clients d-flex flex-column">
                <Helmet>
                    <title>Clienti - Leandro Agostini</title>
                    <meta name="description" content="Ho lavorato per committenti come Fondazione per la Cultura Torino, Fondazione Torino Musei, Casa Teatro Ragazzi e Giovani, oltre a molti soggetti privati." />
                </Helmet>
                <Container fluid={true} className="position-relative">
                    <Row className="align-items-center">
                        <Col lg={6} className="">
                            <div className="img-fixed clients-img">
                                <h1 className="title title-fixed title-white">Clienti</h1>
                            </div>
                        </Col>
                        <Col lg={6} className="mb-lg-5 pt-lg-5">
                            <Row className="align-items-start mt-5 mb-5">
                                {clientList}
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="footer-wrap mt-auto footer-light">
                    <Footer is_fixed={true} />
                </div>
            </div>
        )

    }
    
};

export default Clients;