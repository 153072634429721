import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import ContactForm from '../../components/ContactForm';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/Footer';
import MapContainer from '../../components/MapContainer';

import gsap from 'gsap';

import { isMobile } from "react-device-detect";


class Contact extends Component {

    componentDidMount(){
        // faccio in modo che il cursore si ingrandisca sui data-hover
        if (!isMobile){
            var cursor = document.getElementById("cursor-dot-outline");
            document.querySelectorAll("[data-hover]").forEach(el => {
                el.addEventListener("mouseenter", () => {
                    cursor.style.transform ="translate(-50%, -50%) scale(1)";
                });
                el.addEventListener("mouseleave", () => {
                    cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                });
            });
        }

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })
    }

    render(){
        return(
            <div className="page">
                <Helmet>
                    <title>Contatti - Leandro Agostini</title>
                    <meta name="description" content="Il mio studio è in via Mentana 1/b, a due passi dal Parco del Valentino." />
                </Helmet>
                <div className="">
                    <Container fluid={true} className="align-items-stretch">
                        <Row className="">
                            <Col lg={6} className="">
                                <div className="map-wrapper img-fixed">
                                    <MapContainer />
                                    <h1 className="title title-fixed">Contatti</h1>
                                </div>
                            </Col>
                            <Col lg={6} className="pt-lg-5">
                                <div className="px-lg-4 pt-lg-5"> 
                                    <h2 className="subtitle pt-5 mb-2">Contatti</h2>
                                    <div className="mb-2 d-flex align-items-start">
                                        <div>
                                            <span className="contact-label d-block">Indirizzo</span>
                                            <address className="mb-0">Via Mentana 1/b - 10133 Torino, Italia</address>
                                        </div>
                                    </div>
                                    <div className="mb-2 d-flex align-items-start">
                                        <div>
                                            <span className="contact-label d-block">Telefono</span>
                                            <a data-hover className="contact-link" href="tel:00390112420516">(+39) 011 2420516</a><br />
                                            <a data-hover className="contact-link" href="tel:00393393541852">(+39) 339 3541852</a>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3 d-flex align-items-start">
                                        <div>
                                            <span className="contact-label d-block">E-mail</span>
                                            <a data-hover className="contact-link" href="mailto:info@agostinileandro.it">info@agostinileandro.it</a>
                                        </div>
                                    </div> */}
                                    <div className="mt-5">
                                        <h2 className="subtitle pt-2 mb-2">Social</h2>
                                        <a data-hover href="https://www.facebook.com/leandro.agostini.14" className="mr-3 contact-icon" rel="noopener" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="16" >
                                                <path d="M279.1,288l14.2-92.7h-88.9v-60.1c0-25.4,12.4-50.1,52.2-50.1h40.4V6.3c0,0-36.7-6.3-71.7-6.3
                                                    c-73.2,0-121.1,44.4-121.1,124.7v70.6H22.9V288h81.4v224h100.2V288H279.1z"/>
                                            </svg>
                                        </a>
                                        <a data-hover href="https://www.instagram.com/leandro.agostini.60/" className="mr-3 contact-icon" rel="noopener" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"  width="26">
                                                <path d="M224.1,141c-63.6,0-114.9,51.3-114.9,114.9s51.3,114.9,114.9,114.9S339,319.5,339,255.9S287.7,141,224.1,141z M224.1,330.6
                                                    c-41.1,0-74.7-33.5-74.7-74.7s33.5-74.7,74.7-74.7s74.7,33.5,74.7,74.7S265.2,330.6,224.1,330.6L224.1,330.6z M370.5,136.3
                                                    c0,14.9-12,26.8-26.8,26.8c-14.9,0-26.8-12-26.8-26.8s12-26.8,26.8-26.8S370.5,121.5,370.5,136.3z M446.6,163.5
                                                    c-1.7-35.9-9.9-67.7-36.2-93.9c-26.2-26.2-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9,0C95.8,35.1,64,43.3,37.7,69.5
                                                    s-34.4,58-36.2,93.9c-2.1,37-2.1,147.9,0,184.9c1.7,35.9,9.9,67.7,36.2,93.9s58,34.4,93.9,36.2c37,2.1,147.9,2.1,184.9,0
                                                    c35.9-1.7,67.7-9.9,93.9-36.2c26.2-26.2,34.4-58,36.2-93.9C448.7,311.3,448.7,200.5,446.6,163.5L446.6,163.5z M398.8,388
                                                    c-7.8,19.6-22.9,34.7-42.6,42.6c-29.5,11.7-99.5,9-132.1,9s-102.7,2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6
                                                    c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7,9-132.1c7.8-19.6,22.9-34.7,42.6-42.6c29.5-11.7,99.5-9,132.1-9s102.7-2.6,132.1,9
                                                    c19.6,7.8,34.7,22.9,42.6,42.6c11.7,29.5,9,99.5,9,132.1S410.5,358.6,398.8,388z"/>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="mt-5 mb-lg-5 pb-5">
                                        <h2 className="subtitle pt-2 mb-2">Scrivimi</h2>
                                        <ContactForm />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="footer-wrap mt-auto">
                        <Footer is_fixed={true} />
                    </div>
                </div>
            </div>
        )
    }

    
}

export default Contact