import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';  


class MapContainer extends Component{
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
        });

    onInfoWindowClose = (props) => {
        if (this.state.showingInfoWindow) {
        this.setState({
            showingInfoWindow: false,
            activeMarker: null
        })
        }
    };

    render() {
        
        const style = {
        width: '100%',
        height: '100%'
        }

        return (
        <Map google={this.props.google}
            style={style}
            initialCenter={{
            lat: 45.056785005013836,
            lng: 7.694962584829943
            }}
            zoom={14} 
            styles = {[{
            stylers:  [
                { hue: "#5f5f5f" },
                { saturation: -100 },
                { lightness: -13 },
                { gamma: 1.4 }
            ]
            }]}
            scrollwheel={false}
        >
            <Marker onClick={this.onMarkerClick} 
                name={"Leandro Agostini"} 
                position={{ lat: 45.056785005013836, lng: 7.694962584829943 }}
                />

            <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow} 
                onClose={this.onInfoWindowClose}
                >
                <div>
                    <h4>{this.state.selectedPlace.name}</h4>
                    <p>Via Mentana 1/b - 10133 Torino (Italia)<br/>
                    Tel: 0114364665 <br/> 
                    E-mail: info@agostinileandro.it </p>
                </div>
            </InfoWindow>
        </Map>
        )
    }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCRfleKzhy765t3PO41eB6x79pUMHJifuI'
})(MapContainer);
