import React from "react";
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-component' ;
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item, DefaultLayout } from 'react-photoswipe-gallery'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/Footer";

import axios from 'axios';
import gsap from 'gsap';

import { isMobile } from "react-device-detect";


const masonryOptions = {
    transitionDuration: 0,
    //columnWidth: 250,
    //fitWidth: true,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }


class Signs extends React.Component {

    state = {
        signs: [ ],
    }

    componentDidMount(){

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })

        axios.get(process.env.REACT_APP_BACKEND_URL + '/signs')
            .then(res => {
                //console.log(res)
                this.setState({
                    signs: res.data,
                })

                // faccio in modo che il cursore si ingrandisca sui data-hover
                if (!isMobile){
                    var cursor = document.getElementById("cursor-dot-outline");
                    document.querySelectorAll("[data-hover]").forEach(el => {
                        el.addEventListener("mouseenter", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(1)";
                        });
                        el.addEventListener("mouseleave", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                        });
                    });
                }


            })

    }

    render(){

        const { signs } = this.state;
        const signList = signs.length ? (
            signs.map( (sign, index) => {
                return (
                    <Item key={index}
                        original={process.env.REACT_APP_BACKEND_URL + sign.preview.url}
                        thumbnail={process.env.REACT_APP_BACKEND_URL + sign.preview.url}
                        width={sign.preview.width}
                        height={sign.preview.height}
                        title={sign.title}
                    >
                        {({ ref, open }) => ( 
                            <div onClick={open} className={`d-block grid-sizer sign sign-dim-${sign.dimension}`}>
                                <img ref={ref}  src={process.env.REACT_APP_BACKEND_URL + sign.preview.url} className="img-fluid w-100" alt={sign.title} />
                                <div className="sign-info d-flex flex-column">
                                    <p className="sign-info-title text-uppercase mt-auto mb-0">
                                        {sign.title}
                                    </p>
                                    <p className="sign-info-year text-uppercase mb-2">
                                        { sign.date ? sign.date
                                            :
                                            null
                                        }
                                    </p>
                                    <p className="sign-info-text mb-0">
                                        {sign.description}
                                    </p>
                                </div>
                            </div>
                        )}
                    </Item>
                    
                      /*  <a href={process.env.REACT_APP_BACKEND_URL + sign.preview.url} key={sign.id} className={`d-block grid-sizer sign sign-dim-${sign.dimension}`}>
                         <img src={process.env.REACT_APP_BACKEND_URL + sign.preview.url} alt={sign.name} className="img-fluid w-100"/>
                       
                           
                         <div className="sign-info d-flex flex-column">
                                <p className="sign-info-title text-uppercase mt-auto mb-0">
                                    {sign.title}
                                </p>
                                <p className="sign-info-year text-uppercase mb-2">
                                    {moment(sign.year).format('YYYY')}
                                </p>
                                <p className="sign-info-text mb-0">
                                    {sign.description}
                                </p>
                            </div>
                      
                    </a> */
                )
            })
        ) : (
            <div className="text-center">Loading...</div>
        )

        return(
            <div className="page page-height clients d-flex flex-column">
                <Helmet>
                    <title>Segni - Leandro Agostini</title>
                    <meta name="description" content="L’universo dei segni è il mare che circonda la terra del lavoro. I confini non si possono rintracciare con precisione, prendono l’uno le sembianze dell’altro" />
                </Helmet>
                
                    <div className="signs-line align-items-center">
                        <h1 className="title signs-title">Segni</h1>
                       
                        <div className="signs-wall">
                        
                            {/* <Row className="align-items-center mt-5">
                                {signList}
                            </Row> */}
                          
                            <Gallery zoomButton={false} shareButton={false} fullscreenButton={false}>
                                <Masonry
                                    className={'my-gallery-class'} // default ''
                                    elementType={'div'} // default 'div'
                                    options={masonryOptions} // default {}
                                    disableImagesLoaded={false} // default false
                                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                                >
                                    <div className="d-block grid-sizer sign sign-dim-piccolo px-3">
                                        <p><i>Ferma la nave, ché udire tu possa il nostro canto. (Omero, Odissea, XII)</i></p> 
                                        <p>L’universo dei segni è il mare che circonda la terra del lavoro. I confini fra le due distese non si possono rintracciare con precisione, si confondono, si influenzano, si nutrono, prendono l’uno le sembianze dell’altro. Lo scambio fra i due mondi è nell’ordine naturale delle cose. Il gruppo ACE, l’associazione “i love toret”, i Soggetti, Ciau Bale, sono stati affluenti.</p>
                                        <p>Oggi nel mare dei segni nuotano liberamente disegni a tecnica mista, disegni rigorosamente a matita - i miei preferiti -, progetti e oggetti di design, grafiche applicate, video, racconti, capricci, fughe in avanti e mappe dei desideri. Con Gian Luca Favetto abbiamo messo in acqua “Il teatro del mondo”. Nuota già con un certo stile, seguendo non una ma molteplici rotte. Frutto, come sempre, di un lavoro d’arte e di artigianato che è plurale, intrecciato, meticcio.</p>
                                    </div>
                                    {signList}
                                </Masonry>
                            </Gallery>
                        </div>
                    </div>
                
                <div className="footer-wrap pt-5 mt-auto">
                    <Footer />
                </div>
            </div>
        )

    }
    
};

export default Signs;