import React from 'react';
import ReactDOM from 'react-dom';
import App from "./containers/App";  
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";  
import "./style.min.css";

import { ParallaxProvider } from 'react-scroll-parallax';

ReactDOM.render(
  <Router>
    <ParallaxProvider>
    <App />
    </ParallaxProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
