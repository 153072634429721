import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col} from 'react-bootstrap';
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';
import Footer from '../../components/Footer';

import gsap from 'gsap';


class ThankYou extends React.Component {

    componentDidMount(){

        const acceptedCookies = Cookies.get("gdprCookie");

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })

        if (acceptedCookies === "true") {
            ReactGA.event({
                category: "FormContattiInviato",
                action: "FormContatti",
                label: "Form Contatti Inviato", // optional
                value: 1, // optional, must be a number
            });
        }
    }

    render(){

        return(

            <div className="page thankyou">
                <Helmet>
                    <title>Thank you - Leandro Agostini</title>
                    <meta name="description" content="Grazie per avermi scritto, risponderò il prima possibile" />
                </Helmet>
                <Container fluid={true} className="position-relative">
                    <Row className="align-items-stretch">
                        <Col lg={6} className="">
                            <div className="img-fixed thankyou-img">
                                <h1 className="title title-fixed title-white sr-only">Ciao</h1>
                            </div>
                        </Col>
                        <Col lg={6} className="mb-5 pt-lg-5" >
                            <div className="px-lg-4 pt-lg-5 pb-5 pb-lg-3">
                                <h2 className="subtitle pt-5 mb-5">Ciao</h2>
                                <p><strong>T</strong>: No, no, qua ci vuole un saluto per bene, da peccatore umile. Noi ti salutiamo con, proprio, non sappiamo nemmeno… scrivi, ti salutiamo con la nostra faccia sotto i tuoi piedi, proprio il massimo, senza chiederti nemmeno di stare fermo, puoi muoverti!</p>
                                <p><strong>B</strong>: Cioè, che vuol dire?</p>
                                <p><strong>T</strong>: La faccia sotto i piedi e può camminare; quello pensa siamo proprio due umili.</p>
                                <p><strong>B</strong>: Una bellissima immagine, la nostra faccia sotto i tuoi piedi e puoi muoverti quanto ti pare e piace e noi zitti sotto.</p>
                                <p><strong>T</strong>: Scusa il paragone di prima tra la mosca e il frate, non volevamo minimamente offenderti,  i tuoi peccatori di prima con la faccia dove sappiamo.</p>
                                <p><strong>B</strong>: Gli si è detto …</p>
                                <p><strong>T</strong>: Con la faccia dove sappiamo.</p>
                                <p><strong>B</strong>: Sempre zitti.</p>
                                <p><strong>T</strong>: Sempre zitti.</p>
                                <p className="mt-5 font-italic">(grazie Massimo e Roberto)</p>
                                <p>L</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-wrap mt-aut">
                        <Footer is_fixed={true} />
                    </div>
                </Container>
            </div>

        )

    }
}

export default ThankYou;