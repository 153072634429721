import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import gsap from 'gsap';


class NoMatch extends React.Component {

    componentDidMount(){

        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })
    }
    render(){

        return(
            <div className="page page-height pag404 d-flex flex-column">
                <Helmet>
                    <title>Pagina non trovata - Leandro Agostini</title>
                    <meta name="description" content="Pagina non trovata" />
                </Helmet>
                <Container fluid={true} className="position-relative">
                    <Row className="align-items-stretch">
                        <Col lg={6} className="">
                            <div className="img-fixed pag404-img">
                                <h1 className="title title-fixed">Pagina non trovata</h1>
                            </div>
                        </Col>
                        <Col lg={6} className="mb-lg-5 pt-lg-5">
                            <div className="px-lg-4 pt-lg-5 pb-5 pb-lg-0 pt-5">
                                <p>La pagina che stai cercando non è disponibile.</p>
                                <p>Puoi <Link data-hover to={'/'} className="d-inline-block" >tornare alla homepage
                            </Link> o <Link data-hover to={'/contact'} className="d-inline-block" >contattarmi qui
                            </Link>.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="footer-wrap mt-auto">
                    <Footer is_fixed={true} />
                </div>
            </div>
        )
    }
}

export default NoMatch