import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import CardProject from '../../components/CardProject';
import Footer from "../../components/Footer";

import Swiper from 'swiper';
import moment from 'moment';

import { isMobile } from "react-device-detect";


class Archive extends Component {

    constructor(props){
        super(props);
        this.mySwiper = null;
    }

    state = {
        projects: [ ],
    }

    componentDidMount(){

        axios.get(process.env.REACT_APP_BACKEND_URL + '/works?_limit=500')
            .then(res => {
                //console.log(res)
                this.setState({
                    projects: res.data.sort(function(a, b){
                        // when formatting the date to timestamp, basically you sort by number. 
                        //return moment(b.year).format('X')-moment(a.year).format('X')
                        return (b.date)-(a.date)
                      }),
                })

                document.querySelectorAll('.swiper-container').forEach(function(elem) {
                    new Swiper(elem, {
                        pagination: ".swiper-pagination",
                        paginationClickable: true,
                        //mousewheel: true,
                        spaceBetween: 0,
                        keyboardControl: true,
                        speed: 600,
                        slidesPerView: 'auto',
                        navigation: {
                            // nextEl: elem.nextElementSibling.nextElementSibling,
                            // prevEl: elem.nextElementSibling,
                            nextEl: elem.parentNode.getElementsByClassName( 'swiper-button-next' ),
                            prevEl: elem.parentNode.getElementsByClassName( 'swiper-button-prev' )
                        }
                    });
                });

                // faccio in modo che il cursore si ingrandisca sui data-hover
                if (!isMobile){
                    var cursor = document.getElementById("cursor-dot-outline");
                    document.querySelectorAll("[data-hover]").forEach(el => {
                        el.addEventListener("mouseenter", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(1)";
                        });
                        el.addEventListener("mouseleave", () => {
                            cursor.style.transform ="translate(-50%, -50%) scale(0.2)";
                        });
                    });
                }
                
            })
    }

    render() {

        const { projects } = this.state;

        var year_precedente = moment('2050-01-01').format('YYYY') ;
        var year_precedente = 2050 ;
        
        var sameYear; var years = [];

        for (var p of projects){
            //var year = moment(p.year).format('YYYY') ;
            var year = p.date;

            if(year == year_precedente){
                sameYear= true;
                year_precedente = year;
            }else{
                sameYear= false;
                year_precedente = year;
                years.push(year);
            }
            //console.log(year);
            //console.log(sameYear);
        }
        //console.log(years);


        const projectList = years.length ? (
        
            years.map( (y, index) => {
                
                let slides = '';
                let nrSlides = 0;
                
                return ( 
                
                    <div id={'archive-' + index } key={index} className="archive-line position-relative"> 
                        <div className="archive-year text-left d-inline-block">{y}</div>
                        <div data-hover className="swiper-navigation d-inline-block">
                            <div data-hover className="swiper-button-prev"></div>
                            <div data-hover className="swiper-button-next"></div>
                        </div>
                        <div className="archive-swiper swiper-container">
                            <div className="swiper-wrapper">
                                {projects.length ? (
                                    slides = projects.map( (project, indexS) => {
                                        if (y === project.date){
                                            nrSlides++;
                                            return (
                                                <div className="swiper-slide" id={'box-' + project.id } key={project.id} data-num={nrSlides}>
                                                    <Link data-hover className="swiper-link swiper-link-archive position-relative" to={'/project/' + project.id }>
                                                        <CardProject project={project} title={project.title} enterdelay={(nrSlides)*.35} />
                                                        {/* <span className="swiper-cardname">{project.title}</span> */}
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    })
                                ): (
                                    null
                                )}
                            </div>
                        </div>
                    </div> 
                )}
            )

        ):(
            <div className="text-center">Loading...</div>
        )

        return(
            <div className="page">
                 <div id="circle" className="circle">{this.state.titleProject}</div>
                <Helmet>
                    <title>Archivio - Leandro Agostini</title>
                    <meta name="description" content="Difficile riassumere in poche parole tutti il mio lavoro. Tutte le discipline che cerco di frequentare si sovrappongono in un’unica risposta, la mia." />
                </Helmet>
                <Container className="" fluid="true">
                    {projectList}
                    <div className="footer-wrap pt-5">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

export default Archive