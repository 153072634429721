import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Cookies } from "react-cookie-consent";
import { Form } from "react-bootstrap";
import Footer from "../../components/Footer";

import gsap from 'gsap';

class Privacy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gdprCookie: Cookies.get('gdprCookie')
        };

      this.acceptCookie = this.acceptCookie.bind(this);
      this.declineCookie = this.declineCookie.bind(this);

    }

    acceptCookie(){
        Cookies.set('gdprCookie', true)
        this.setState({
            gdprCookie: 'true'
        });
    }

    declineCookie(){
        Cookies.set('gdprCookie', false)
        this.setState({
            gdprCookie: 'false'
        });
    }

    componentDidMount(){
        gsap.to(['.title'],.6,{
            y: 30,
            delay:0.6,
            ease:"power3.InOut",
            opacity: 1,
            stagger: {
                amount: .6
            }
        })
    }

    render(){

        return(
            <div className="page privacy">
                <Helmet>
                    <title>Privacy policy - Leandro Agostini</title>
                    <meta name="description" content="Le Norme sulla privacy spiegano quali dati vengono raccolti e con che finalità, le modalità di utilizzo di tali informazioni e i diritti dell'utente" />
                </Helmet>
                <Container fluid={true} className="position-relative">
                    <Row className="align-items-stretch">
                        <Col lg={6} className="">
                           <div className="img-fixed privacy-img">
                                <h1 className="title title-fixed title-white">Privacy</h1>
                            </div>
                        </Col>
                        <Col lg={6} className="mb-5 pt-lg-5" >
                            <div className="px-lg-4 pt-lg-5 pb-5 pb-lg-0">
                                <h2 className="subtitle pt-5 mb-5">Norme sulla privacy</h2>
                                {/* ----{this.state.gdprCookie}----- */}
                                <small className="mb-4 d-block" >Ultima modifica: 19 Marzo 2021</small>
                                <p>Le Norme sulla privacy ti consentono di capire meglio quali dati raccogliamo, perché li raccogliamo e come li utilizziamo. Sono informazioni molto importanti e ci auguriamo che le leggerai con attenzione prima di inoltrare qualsiasi tipo di informazione personale e/o compilare qualunque modulo elettronico presente sul sito stesso.</p>
                                <p>Si tratta di un’informativa che è resa anche ai sensi dell’art. 13 del GDPR 679/2016 - General Data Protection Regulation – a coloro che si collegano al sito web di Leandro Agostini e usufruiscono dei relativi servizi web a partire dall'indirizzo agostinileandro.it. </p>
                                <p>Il sito agostinileandro.it è di proprietà di Leandro Agostini, gestito da Leandro Agostini, che garantisce il rispetto della normativa in materia di protezione dei dati personali (GDPR 679/2016).</p>
                                <h3 className="mt-5 text-bold">Norme sulla privacy</h3>
                                <p>La privacy degli utenti è importante per Leandro Agostini. Nell’informarvi sulle normative cerchiamo di utilizzare un linguaggio chiaro e semplice, qualora non si conoscano termini come cookie, indirizzi IP, pixel tag e browser, potete contattarci per eventuali domande a riguardo.</p>
                                <p>Le nostre Norme sulla privacy spiegano:</p>
                                <ul>
                                    <li>Dati raccolti e finalità.</li>
                                    <li>Modalità di utilizzo di tali informazioni.</li>
                                    <li>Le opzioni che offriamo, compresi la modalità di accesso.</li>
                                </ul>
                                <h4 id="dati" className="mt-4 text-bold">1. Dati raccolti</h4>
                                <p>Raccogliamo dati per offrire servizi migliori a tutti i nostri utenti, ad esempio per capire elementi fondamentali come la lingua parlata dall'utente oppure elementi più complessi come quali annunci potrebbero essere più utili o quali contenuti potrebbero essere creati in base all'interesse dell’utente.</p>
                                <p>Fra i dati personali raccolti tramite il sito, in modo autonomo o tramite terze parti, vi sono: dati di utilizzo, numero di telefono, email, nome, cognome, CAP, ragione sociale, indirizzo, nazione, città, settore di attività, sito web, username, sesso, data di nascita, posizione geografica, professione, immagine e immagine di profilo.</p>
                                <p>Raccogliamo informazioni nei seguenti modi:</p>
                                <h5 className="mt-4 text-bold">Dati forniti dall'utente</h5>
                                <p>Ad esempio, tramite i form del sito. Durante la compilazione di un form chiediamo informazioni personali quali nome, indirizzo e-mail o numero di telefono. </p>
                                <h5 className="mt-4 text-bold">Dati raccolti tramite Google Analytics</h5>
                                <p>Noi utilizziamo la tecnologia di Google Analytics per raccogliere i dati e memorizzare informazioni quando il sito viene visitato, che potrebbero prevedere l'utilizzo di cookie o tecnologie simili per identificare il browser o il dispositivo dell'utente. Utilizziamo queste tecnologie anche per raccogliere e memorizzare informazioni quando l'utente interagisce con il nostro sito. Google può usare cookie che consentono la suddivisione di dati demografici e può eventualmente utilizzare i dati raccolti per contestualizzare e personalizzare gli annunci pubblicitari, condividendoli con gli altri servizi sviluppati da Google.</p>
                                <p>Per ulteriori informazioni, consultate i seguenti link:</p>
                                <ul>
                                    <li><a data-hover href="https://www.google.it/policies/privacy/partners/">https://www.google.it/policies/privacy/partners/</a></li>
                                    <li><a data-hover href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></li>
                                    <li><a data-hover href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a></li>
                                </ul>
                                <p>Puoi impedire l'utilizzo di questi dati da parte di Google Analytics scaricando e installando un componente aggiuntivo del browser.</p>
                                <a data-hover href="https://tools.google.com/dlpage/gaoptout?hl=it">SCARICA IL COMPONENTE AGGIUNTIVO DEL BROWSER</a>
                                <h5 className="mt-4 text-bold">Cosa sono i cookies?</h5>
                                <p>I cookie sono piccole stringhe di testo che un sito invia al dispositivo dell’utente con il fine di ottimizzare la navigazione e i servizi offerti, di migliorare le funzionalità e la fruizione del sito stesso. I cookie vengono usati per gestire le autenticazioni, monitorare le sessioni e memorizzare le preferenze dell’utente.<br/>I cookie possono essere salvati sul dispositivo e rimanere attivi per una durata variabile oppure cancellarsi con la chiusura del browser. </p>
                                <p>Alcuni cookie sono indispensabili in quanto supportano funzioni essenziali del nostro sito web, come l'impostazione della sessione dell'utente. <br/>
                                Oltre ai cookie di navigazione, o di sessione, su questo sito sono usati anche i cookie di funzionalità, che permettono di ricordare le scelte fatte dall’utente (come la lingua o la nazione di provenienza) e di offrire un servizio avanzato e personalizzato.<br/>
                                Utilizziamo inoltre i cookie tecnici per tener traccia, in conformità con la normativa vigente, dell’avvenuta accettazione alla ricezione dei cookie.</p>
                                <h5 className="mt-4 text-bold">Gestione dei cookies</h5>
                                <p>Per default quasi tutti i browser web sono impostati per accettare automaticamente i cookie.<br/>L’utente può decidere di disabilitare l'uso dei cookie o limitarne le funzioni personalizzando le impostazioni del proprio browser. Tramite le preferenze del browser è inoltre possibile eliminare i cookie installati in passato. È importante notare che la disabilitazione, il blocco o l’eliminazione dei cookie possono influire negativamente sull’esperienza d’uso e sulla funzionalità del nostro sito web. Anche la disattivazione dei cookie di terze parti potrebbe interferire con il corretto funzionamento del sito.</p>
                                <p>Le istruzioni su come gestire i cookie nei diversi browser sono consultabili ai seguenti link:</p>
                                <ul>
                                    <li><a data-hover href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a></li>
                                    <li><a data-hover href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a></li>
                                    <li><a data-hover href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">Internet Explorer</a></li>
                                    <li><a data-hover href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">Safari</a></li>
                                    <li><a data-hover href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a></li>
                                </ul>
                                <h4 d="modalita" className="mt-5 text-bold">2. Modalità di utilizzo dei dati raccolti</h4>
                                <p>Utilizziamo i dati raccolti tramite i cookie e altre tecnologie, come i tag di pixel, per migliorare l'esperienza degli utenti e la qualità generale dei nostri servizi. Uno dei prodotti che utilizziamo a tale scopo sui nostri servizi è Google Analytics. <br/>Il Trattamento dei dati è eseguito attraverso procedure informatiche e mezzi telematici.</p>
                                <p>Leandro Agostini tratta le informazioni personali su suo server situato in Germania. Potremmo trattare informazioni personali su un server in un Paese diverso da quello in cui si trova l’utente.</p>
                                <p>Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati categorie di incaricati coinvolti nell’organizzazione del sito (a titolo esemplificativo: personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (quali, ad esempio, fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
                                <h5 className="mt-4 text-bold">Facoltatività del conferimento dei dati</h5>
                                <p>A parte quanto specificato per i dati di navigazione, gli utenti/visitatori sono liberi di fornire i propri dati personali. Il loro mancato conferimento può comportare unicamente l’impossibilità di ottenere quanto richiesto.</p>
                                <h5 className="mt-4 text-bold">Luogo di trattamento dei dati</h5>
                                <p>I trattamenti connessi ai servizi web dei siti sopra indicati hanno luogo presso la sede aziendale di Leandro Agostini (titolare del trattamento dei dati), in Via Mentana 1/b, Torino (TO) e/o presso la sede della società di hosting e/o gestione del sito web e sono curati solo da personale tecnico dell’Ufficio incaricato del trattamento, oppure da eventuali incaricati di occasionali operazioni di manutenzione. Nessun dato derivante dal servizio web viene comunicato o diffuso. I dati personali forniti dagli utenti che inoltrano richieste di invio di materiale informativo sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario. </p>
                                <h5 className="mt-5 text-bold">Titolare del trattamento</h5>
                                <p>Il titolare del trattamento dei dati personali è la azienda Leandro Agostini</p>
                                <h4 id="diritti" className="mt-5 text-bold">3. Diritti dell’utente</h4>
                                <p>Ai sensi del GDPR e della normativa nazionale, l'Utente può esercitare i seguenti diritti: </p>
                                <ul>
                                    <li>Richiedere di accedere ai propri dati personali e di conoscere la logica, le modalità e le finalità del trattamento degli stessi;</li>
                                    <li>Ricevere i dati in un formato elettronico leggibile;</li>
                                    <li>Richiederne l'aggiornamento, la rettifica, l'integrazione, la cancellazione;</li>
                                    <li>Opporsi a determinate attività di trattamento o richiedere la limitazione del trattamento nei casi di inesattezza o trattamento illecito;</li>
                                    <li>Nonché, più in generale, esercitare tutti i diritti che gli sono riconosciuti dalle vigenti disposizioni di legge.</li>
                                </ul>
                                <p>L’utente può presentare le sue richieste in qualunque momento contattando il Titolare del trattamento all’indirizzo Via Mentana 1/b, Torino (TO). </p>

                                <hr className="my-5" />
                                <div className="pb-5"> 
                                    <h2 className="subtitle mt-0 pt-0 mb-4">Impostazioni dei cookies</h2>
                                    <p>Gestisci la modalità di utilizzo dei cookie da parte del browser e la modalità di invio dei dati a servizi come Google Analytics</p>
                                    <Form>
                                        <Form.Check custom
                                            type='radio'
                                            id='radioAccept'
                                            name='radioCookie'
                                            label='Accetto tutti i cookie'
                                            onChange={this.acceptCookie}
                                            checked={this.state.gdprCookie === 'true'}
                                        />
                                        <Form.Check custom
                                            type='radio'
                                            id='radioDecline'
                                            name='radioCookie'
                                            label='Non accetto i cookie'
                                            onChange={this.declineCookie}
                                            checked={this.state.gdprCookie === 'false'}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-wrap mt-auto footer-light">
                        <Footer is_fixed={true} />
                    </div>
                </Container>
            </div>
        )

    }
}



export default Privacy;