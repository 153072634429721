import React, {useEffect, useRef} from "react";
import { Card } from 'react-bootstrap';


import {gsap} from 'gsap';

const CardProject = ({project, title, enterdelay}) => {
    let card = useRef(null);

    useEffect(() => {
        gsap.from(card, .75, {
            delay: enterdelay,
            ease: "power3.out",
            x: 64,
            opacity:0,
            stagger: {
               amount: 0.15
            },
        });
    }, [card]);

    return(
        <Card ref={el => card = el}>

        <Card.Img src={process.env.REACT_APP_BACKEND_URL + project.image.url} className="img-fluid" alt={project.title} />
        <span className="swiper-cardname">{project.title}</span>
        {/* <Card.Body>
            <Card.Title>{project.title}</Card.Title>
        </Card.Body> */}
        </Card>
    )
}

export default CardProject;