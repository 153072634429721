import React from "react";

function BackButton() {

  return (
    <button data-hover
      className="backbutton"
      onClick={() => {
        window.history.back();
      }}
    >
      <svg id="BackArrow" enableBackground="new 0 0 451.111 451.111" height="28" viewBox="0 0 451.111 451.111" width="28" xmlns="http://www.w3.org/2000/svg"><path d="m451.111 193.333h-322.222l112.778-112.777-48.333-48.333-193.334 193.333 193.333 193.333 48.333-48.333-112.777-112.778h322.222z"/></svg>
      <span>indietro</span>
    </button>
  );
}

export default BackButton;