import React, { Component } from 'react';
import {FormControl, FormLabel, FormGroup, FormText, FormCheck, Button}  from 'react-bootstrap';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// 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 vecchia
// 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 nuova

const validationSchema = Yup.object().shape({
    name: Yup.string().required('** Campo obbligatorio').min(2, '** Troppo corto!').max(70, 'Troppo lungo!'),
    email: Yup.string().email('** E-mail non valida').required('** Campo obbligatorio'),
    condizioni: Yup.bool().required('** È necessario accettare il trattamento dei dati personali').oneOf([true], "** È necessario accettare il trattamento dei dati personali"),
    message: Yup.string().required('** Campo obbligatorio'),
  });
 
class ContactForm extends Component {

    render(){
        return(
            <div>
                <Formik 
                    initialValues={{ name: '', email: '', condizioni: false, message: ''}}
                    initialErrors={{ name: true, email: true, condizioni: true, message: true }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        // make async call
                        axios.post(process.env.REACT_APP_BACKEND_URL + '/contact-forms', {
                                name: values.name,
                                email: values.email,
                                message: values.message,
                            })
                            .then(response => {
                                // Handle success.
                                console.log('Well done!');
                            })
                            .catch(error => {
                                // Handle error.
                                console.log('An error occurred:', error);
                            });
                        //console.log('submit: ', values);

                        // send an email by using the email plugin
                        axios.post(process.env.REACT_APP_BACKEND_URL + '/email/', {
                            to: `${process.env.REACT_APP_EMAIL_RECEIVER}`,
                            from: `${values.email}`,
                            replyTo: `${values.email}`,
                            subject: 'Nuovo messaggio dal sito',
                            text: `
                                Nome: ${values.name}
                                Email: ${values.email}
                                Messaggio: ${values.message}
                            `,
                            html: `
                                <h2>Nuovo messaggio dal sito</h2>
                                <strong>Nome:</strong> ${values.name}<br />
                                <strong>Email:</strong> ${values.email}<br />
                                <strong>Messaggio:</strong> ${values.message}
                            `
                        })
                        .then(response => {
                            // Handle success.
                            console.log('Well done!');
                            window.location = '/thank-you/';
                        })
                        .catch(error => {
                            // Handle error.
                            console.log('An error occurred:', error);
                        });

                        setSubmitting(false);
                    }}>
                    {({values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <FormLabel>Nome e cognome</FormLabel>
                                <Field name="name" placeholder="" type="input" as={FormControl} isValid={!errors.name} onChange={handleChange} value={values.name}/>
                                <FormText className="form-error"><ErrorMessage name="name" /></FormText>
                            </FormGroup>
                            <FormGroup>
                               <FormLabel>E-mail</FormLabel>
                                <Field name="email" placeholder="" type="email" as={FormControl} isValid={!errors.email} onChange={handleChange} value={values.email} />
                                <FormText className="form-error"><ErrorMessage name="email" /></FormText>
                            </FormGroup>
                            <FormGroup>
                               <FormLabel>Messaggio</FormLabel>
                               <FormControl name="message" as="textarea" rows="3" isValid={!errors.message} onChange={handleChange} value={values.message} />
                            </FormGroup>
                            <FormGroup>
                                <Field id="condizioni" name="condizioni" type="checkbox" as={FormCheck} label="Accetto il trattamento dei dati personali" custom isValid={!errors.condizioni} />
                                <FormText className="form-error"><ErrorMessage name="condizioni" /></FormText>
                                <FormText className="help-text">Ai sensi dell’articolo 13 del GDPR 679/2016 si informa che i dati forniti tramite la compilazione di questo form saranno trattati esclusivamente per dare riscontro alle vostre richieste e trattati in modo lecito, corretto e trasparente.</FormText>
                            </FormGroup>
                            <div>
                                <Button data-hover type="submit" disabled={isSubmitting}>Invia messaggio</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }

}

export default ContactForm

