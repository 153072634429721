import React from 'react';

import { Link } from 'react-router-dom';

import Toggle from '../../components/Toggle';
import useDarkMode from 'use-dark-mode';

import logoLeandro from '../../assets/images/agostini_leandro.png';
import Nav from "../../components/Nav";

import DarkMode from '../../components/DarkMode';


function Header (){

    function checkPage(){
        (window.location.pathname == '/') ?
            window.scrollTo(0, 0)
        :
        window.location.href = "/";
    }
   
    return ( 
        <header className="fixed-top">

            <div className="logo-wrap" data-hover onClick={checkPage}>
                <img src={ logoLeandro } alt="Agostini Leandro" className="logo" style={{ 'width': '200px'}} />
            </div>

            <div className="d-none d-lg-block">
                <DarkMode/>
            </div>

            <div className="menu-mobile d-lg-none">
                <Nav is_mobile={true} />
            </div>

        </header>
    )

}


export default Header